<template>
    <CRTag :type="tagType" class="tag">
        <h6>{{ checkOutStatus.title }}</h6>
        <p v-if="checkOutStatus.subtitle">
            {{ checkOutStatus.subtitle }}
        </p>
    </CRTag>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import { getCheckOutStatusTitle, getTagType } from '@/helpers/StatusesHelper';

    export default {
        name: 'OMStatus',
        components: { CRTag },
        props: {
            status: {
                type: String,
                default: '',
            },
            paymentType: {
                type: String,
                default: '',
            },
        },
        computed: {
            checkOutStatus() {
                return getCheckOutStatusTitle(this.status, this.paymentType);
            },

            tagType() {
                return getTagType(this.status);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .tag {
        text-align: end;

        :deep(.tag-container__text) {
            @include column-align-end;
        }

        p {
            font-weight: 400;
            font-style: italic;
            font-size: $font-14;
            line-height: 110%;
        }
    }
</style>
