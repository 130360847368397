<template>
    <div class="check-out-card">
        <div v-for="slot in arrayOfNumbers" :key="slot" class="inner-item">
            <slot :name="`inner-item-${slot}`" />
        </div>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'CheckOutCard',
        props: {
            slotsCount: {
                type: Number,
                default: 0,
            },
        },
        computed: {
            arrayOfNumbers() {
                let arr = [];
                for (let i = 1; i <= this.slotsCount; i++) {
                    arr.push(i);
                }

                return arr;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .check-out-card {
        background: $white;
        box-shadow: $box-shadow-dark;

        border-radius: 10px;

        padding: 10px;

        transition: all 0.2s linear;

        border: 3px solid transparent;

        .inner-item {
            padding: 10px 0;

            border-bottom: 1px solid $grey-line;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
</style>
