import { PaymentTypeEnum } from '@/utils/enums';
import { translation } from '@/helpers/TranslationHelper';

export const getPaymentTypeName = (paymentType) => {
    if (paymentType === PaymentTypeEnum.MONTONIO) {
        return translation('common.montonio');
    }

    if (paymentType === PaymentTypeEnum.BANK_ACCOUNT) {
        return translation('common.bankTransfer');
    }

    if (paymentType === PaymentTypeEnum.CASH) {
        return translation('common.cash');
    }
};
