import { CheckOutStatusEnum } from '@/utils/enums';
import { translation } from '@/helpers/TranslationHelper';

export const getCheckOutStatusTitle = (status, paymentType) => {
    const title = translation(`orderManagement.statuses.${status}`);

    let subtitle = '';

    if (status === CheckOutStatusEnum.ORDER_APPROVED) {
        subtitle = translation('orderManagement.statuses.order_approved_subtitle');
    }

    if (status === CheckOutStatusEnum.WAITING_PAYMENT) {
        subtitle = translation(`orderManagement.statuses.waiting_payment_${paymentType}_subtitle`);
    }

    return {
        title,
        subtitle,
    };
};

export const getTagType = (status) => {
    if (
        status === CheckOutStatusEnum.NEW ||
        status === CheckOutStatusEnum.ORDER_REQUEST ||
        status === CheckOutStatusEnum.ORDER_APPROVED ||
        status === CheckOutStatusEnum.WAITING_PAYMENT
    ) {
        return 'awaiting';
    }

    if (status === CheckOutStatusEnum.PAID) {
        return 'success';
    }

    if (status === CheckOutStatusEnum.DECLINED || status === CheckOutStatusEnum.NOT_PAID) {
        return 'danger-fill';
    }

    if (status === CheckOutStatusEnum.COMPLETED) {
        return 'grey';
    }

    return 'awaiting';
};
