<template>
    <div class="filter-section">
        <div class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>{{ $t('common.orderStatusFilter') }}</h5>
            <CRSelect class="select" :data="statusFilterData" :selected="params.status" @select="handelStatusSelect" />
        </div>
        <div class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>{{ $t('common.paymentFilter') }}</h5>
            <CRSelect class="select" :data="paymentTypeFilterData" :selected="params.paymentType"
                @select="handelPaymentTypeSelect" />
        </div>
        <div class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>{{ $t('common.sortBy') }}</h5>
            <CRSelect class="select" :data="sortByFilterData" :selected="params.selectedSortBy"
                @select="handelSortBySelect" />
        </div>
        <div class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>{{ $t('common.sortByTrashed') }}</h5>
            <CRSelect class="select" :data="archiveFilterData" :selected="params.deleted"
                @select="handleSortByDeleted" />
        </div>
        <div v-if="isAdmin" class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>Rinkinys</h5>
            <CRSelect class="select" :data="mealkits" :selected="params.mealkit" @select="handelMeakitSelect" />
        </div>
        <div v-if="isAdmin" class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>Paieška</h5>
            <CRInput v-model="params.search" class="input" :label="$t('common.search')" @onChange="searchChanged"
                :placeholder="$t('placeholder.search')" />
        </div>
        <div v-if="isAdmin" class="filter-item" :class="{ 'filter-item-lt': $i18n.locale === 'lt' }">
            <h5>Nuolaidos kodas</h5>
            <CRInput v-model="params.discount" class="input" :label="$t('common.discount')" @onChange="discountChanged"
                :placeholder="$t('placeholder.discount')" />
        </div>
    </div>
</template>

<script>
// import _ from 'loadash';
import CRSelect from '@/components/Common/Select/CRSelect.vue';
import {
    sortByFilterData,
    paymentTypeFilterData,
    statusFilterData,
    archiveFilterData,

} from '@/config/orderManagement/orderManagement';
import { mapGetters, mapActions } from 'vuex';
import CRInput from '../Common/Inputs/CRInput.vue';
import { MealKitApi } from '@/api';


export default {
    name: 'FilterSection',
    components: { CRSelect, CRInput },

    data() {
        return {
            sortByFilterData,
            paymentTypeFilterData,
            statusFilterData,
            archiveFilterData,
            mealkits: [],
        };
    },
    computed: {
        ...mapGetters('auth', ['isAdmin']),
        ...mapGetters('orderManagement', ['params']),
    },
    created() {
        this.loadMealkits();
    },
    methods: {
        ...mapActions('orderManagement', ['getAllCheckOuts']),
        async discountChanged(item) {
            try {
                await this.getAllCheckOuts({ discount: item });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
        async handelMeakitSelect(item) {
            console.log(item);
            try {
                await this.getAllCheckOuts({ mealkit: item.value });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
        async searchChanged(item) {

            try {
                await this.getAllCheckOuts({ search: item });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
        async handleSortByDeleted(item) {
            try {
                await this.getAllCheckOuts({ deleted: item.value });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },

        async handelSortBySelect(item) {
            try {
                await this.getAllCheckOuts({
                    sortField: item.field,
                    sortDirection: item.fieldValue,
                    selectedSortBy: item.value,
                });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },

        async handelPaymentTypeSelect(item) {
            try {
                await this.getAllCheckOuts({ paymentType: item.value });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },

        async handelStatusSelect(item) {
            try {
                await this.getAllCheckOuts({ status: item.value });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
        async loadMealkits() {
            try {
                const response = await MealKitApi.getAll();
                this.mealkits = response.data.map((kit) => ({
                    value: kit.id, // Assuming `id` is a unique identifier for the meal kit
                    label: kit.name.lt || 'Rinkinys be pavadinimo' // Replace `kit.name` with the appropriate field from your API response, or a fallback translation
                }));
            } catch (error) {
                this.$filters.toast(error.message);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.filter-section {
    .filter-item {
        width: 100%;
        width: 220px;

        margin-bottom: 18px;

        &.filter-item-lt {
            width: 250px;
        }

        h5 {
            margin-bottom: 8px;
        }

        .select {
            width: 100%;
        }
    }
}

@include media($md) {
    .filter-section {
        @include row-align-end;

        flex-wrap: wrap;

        .filter-item {
            width: 220px;

            margin-bottom: 16px;
            margin-right: 32px;

            h5 {
                margin-bottom: 8px;
            }

            .select {
                width: 100%;
            }
        }

        .toggle-filter {
            @include row-align-center;

            margin-bottom: 20px;
        }
    }
}

@include media($lg) {
    .filter-section {
        @include row-align-end;

        flex-wrap: wrap;

        .filter-item {
            width: 220px;

            margin-bottom: 16px;
            margin-right: 32px;

            h5 {
                margin-bottom: 8px;
            }

            .select {
                width: 100%;
            }
        }
    }
}
</style>
