<template>
    <BasicModal @close="closeModal">
        <div class="container">
            <h2>{{ title }}</h2>
            <p v-if="description">{{ description }}</p>
            <div class="btn__wrapper">
                <CRButton class="btn" color="danger" @click="closeModal">
                    {{ $t('buttons.no') }}
                </CRButton>
                <CRButton class="btn" @click="confirm" :loading="isLoading">
                    {{ $t('buttons.yes') }}
                </CRButton>
            </div>
        </div>
    </BasicModal>
</template>

<script>
    import BasicModal from '@/components/Modals/BasicModal.vue';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';

    export default {
        name: 'ConfirmationModal',
        components: { BasicModal, CRButton },
        props: {
            title: {
                type: String,
                default: '',
            },
            description: {
                type: String,
                default: '',
            },
            isLoading: {
                type: Boolean,
                default: false,
            },
        },
        emits: ['close', 'confirm'],
        methods: {
            closeModal() {
                if (!this.isLoading) {
                    this.$emit('close');
                }
            },
            confirm() {
                this.$emit('confirm');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .container {
        @include column-align-start;

        width: 100%;

        padding: 48px;

        h2 {
            max-width: 338px;
            color: $primary;
        }

        p {
            margin-top: 18px;
        }

        .btn__wrapper {
            @include row-align-start;

            margin-top: 18px;

            .btn:first-child {
                margin-right: 8px;
            }
        }
    }
</style>
