import { translation } from '@/helpers/TranslationHelper';
import { PaymentTypeEnum, CheckOutStatusEnum } from '@/utils/enums';

export const sortByFilterData = [
    { value: 1, label: translation('common.newestCreated'), field: 'created_at', fieldValue: 'desc' },
    { value: 2, label: translation('common.oldestCreated'), field: 'created_at', fieldValue: 'asc' },
];

export const paymentTypeFilterData = [
    { value: '', label: translation('common.none') },
    { value: PaymentTypeEnum.CASH, label: translation('common.cash') },
    { value: PaymentTypeEnum.MONTONIO, label: translation('common.montonio') },
    { value: PaymentTypeEnum.BANK_ACCOUNT, label: translation('common.bankTransfer') },
];

export const archiveFilterData = [
    { value: '', label: translation('common.none') },
    { value: 'trashed', label: translation('common.deleted') },
    { value: 'all', label: translation('common.all') },
]

export const statusFilterData = [
    { value: '', label: translation('common.none') },
    // for Individual
    {
        value: CheckOutStatusEnum.ORDER_REQUEST,
        label: translation('orderManagement.statuses.order_request'),
    },
    {
        value: CheckOutStatusEnum.ORDER_APPROVED,
        label: translation('orderManagement.statuses.order_approved'),
    },
    // common
    {
        value: CheckOutStatusEnum.DECLINED,
        label: translation('orderManagement.statuses.declined'),
    },
    {
        value: CheckOutStatusEnum.WAITING_PAYMENT,
        label: translation('orderManagement.statuses.waiting_payment'),
    },
    {
        value: CheckOutStatusEnum.PAID,
        label: translation('orderManagement.statuses.paid'),
    },
    {
        value: CheckOutStatusEnum.NOT_PAID,
        label: translation('orderManagement.statuses.not_paid'),
    },
    {
        value: CheckOutStatusEnum.COMPLETED,
        label: translation('orderManagement.statuses.completed'),
    },
];

export const regularOrderChangeStatusButtons = [
    {
        id: 1,
        class: 'mr-10',
        status: CheckOutStatusEnum.COMPLETED,
        color: 'primary',
        renderConditionStatus: CheckOutStatusEnum.PAID,
        title: translation('buttons.completed'),
        pattern: 'fill',
    },
    {
        id: 2,
        class: 'mr-10',
        status: CheckOutStatusEnum.PAID,
        color: 'primary',
        renderConditionStatus: CheckOutStatusEnum.WAITING_PAYMENT,
        title: translation('buttons.paid'),
        pattern: 'fill',
    },
    {
        id: 3,
        class: 'mr-10',
        status: CheckOutStatusEnum.NOT_PAID,
        color: 'danger',
        renderConditionStatus: CheckOutStatusEnum.WAITING_PAYMENT,
        title: translation('buttons.notPaid'),
        pattern: 'fill',
    },
    {
        id: 4,
        class: 'mr-10',
        status: CheckOutStatusEnum.DECLINED,
        color: 'danger',
        renderConditionStatus: CheckOutStatusEnum.WAITING_PAYMENT,
        title: translation('buttons.cancel'),
        pattern: 'outline',
    },
];
