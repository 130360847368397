<template>
    <div class="check-out-item" :class="{'check-out-item__deleted' : checkOut.deleted }">
        <CheckOutCard class="mobile-card" :slots-count="5">
            <template #inner-item-1>
                <div class="f-align-start-justify-between">
                    <!-- Order number -->
                    
                    <OMTitleSection :title="$t('orderManagement.orderNumber')" :text="checkOut.orderNumber" />
                    <!-- Order status -->
                    
                    <OMStatus
                        class="status"
                        :status="checkOut.status"
                        :payment-type="checkOut.payment?.paymentableType"
                    />
                </div>
            </template>

            

            <template #inner-item-2>
                <!-- Order date -->
                <OMTitleSection :title="$t('orderManagement.orderDate')" :text="checkOut.createdAt" />
                <div class="kit-events" v-if="renderKitEvents(checkOut).length">
                    <div class="kit-events__tag-wrapper">
                        <CRTag
                            v-for="(item, index) in renderKitEvents(checkOut)"
                            :key="index"
                            :type="item.tagType"
                            class="tag"
                        >
                            <h6>
                                {{ item.title }}
                                <span v-if="item?.subtitle">
                                    {{ item.subtitle }}
                                </span>
                            </h6>
                        </CRTag>
                    </div>
                </div>
            </template>

            <template #inner-item-3>
                <OMTitleSection :title="'Nuolaidos kodas'" :text="''"/>
                <div v-for="discountCode in checkOut.discountCodes" :key="discountCode.id">
                    <b>{{ discountCode.code }}</b>
                </div>
            </template>

            <template #inner-item-4>
                <OMTitleSection v-if="isAdmin" :title="'Klientas'" :text="`${checkOut.deliveryUser.firstName} ${checkOut.deliveryUser.lastName} (${checkOut.deliveryUser.email})`" />
            </template>

            <template #inner-item-5>
                <div class="footer">
                    <!-- Number of kits -->
                    <OMTitleSection :title="$t('orderManagement.numberOfKits')" :text="checkOut.kitsAmount" />

                    <!-- Price -->
                    <OMTitleSection class="f-column-align-end">
                        <h2 v-if="checkOut.prices">€{{ checkOut.prices.total_price }}</h2>
                        <p v-if="checkOut.payment">{{ getPaymentTypeName(checkOut.payment.paymentableType) }}</p>
                    </OMTitleSection>

                    <CRButton class="btn" @click="openCheckOutPage">
                        {{ $t('buttons.orderDetails') }}
                    </CRButton>
                </div>
            </template>

        </CheckOutCard>

        <div class="desktop-row">
            <!-- Order number -->
            <div class="row-item order-number">
                <CRButton @click="openCheckOutPage" pattern="thin">{{ checkOut.orderNumber }}</CRButton>
            </div>
            <div class="vertical-separator" />

            <!-- Order date -->
            <div class="row-item order-date">
                <p>{{ checkOut.createdAt }}</p>
            </div>
            <div class="vertical-separator" />

            <!-- Order status -->
            <div class="row-item order-status">
                <OMStatus :status="checkOut.status" :payment-type="checkOut.payment?.paymentableType" />
            </div>
            <div class="vertical-separator" />

            <!-- Payment method -->
            <div class="row-item payment-method">
                <p v-if="checkOut.payment">{{ getPaymentTypeName(checkOut.payment.paymentableType) }}</p>
                <p v-else>-</p>
            </div>
            <div class="vertical-separator" />

            <!-- Kit events -->
            <div class="row-item kit-events">
                <div v-if="renderKitEvents(checkOut).length" class="kit-events__tag-wrapper">
                    <CRTag
                        v-for="(item, index) in renderKitEvents(checkOut)"
                        :key="index"
                        :type="item.tagType"
                        class="tag"
                    >
                        <h6>{{ item.title }}</h6>
                        <p v-if="item?.subtitle">
                            {{ item.subtitle }}
                        </p>
                    </CRTag>
                </div>
                <p v-else>-</p>
            </div>
            <div v-if="isAdmin" class="vertical-separator" />

            <!-- User info -->
            <div class="row-item user-info" v-if="isAdmin">
                <!-- <p>{{ checkOut.kitsAmount }}</p> -->
                <div class="bold">{{ checkOut.deliveryUser.firstName }} {{ checkOut.deliveryUser.lastName }}</div>
                <div><a class="cr-button cr-button_size-base cr-button_thin-primary" :href="`mailto:${checkOut.deliveryUser.email}`">{{ checkOut.deliveryUser.email }}</a></div>
            </div>
            <div class="vertical-separator" />

             <!-- Discount code -->
            <div class="row-item kit-amount">
                <div v-for="discountCode in checkOut.discountCodes" :key="discountCode.id">
                    {{ discountCode.code }}
                </div>
            </div>

            <div class="vertical-separator" />

            <!-- Order price -->
            <div class="row-item order-price">
                <p v-if="checkOut.prices">€{{ checkOut.prices.total_price }}</p>
                <p v-else>-</p>
            </div>
            <div class="vertical-separator" />

            <!-- Actions -->
            <div class="row-item actions">
                <div class="f-column-align-end" v-if="isAdmin && checkOut.deleted === false">
                    <CRButton
                        v-if="isPaidAndNotPaidBtnVisible"
                        pattern="thin"
                        @click="openConfirmationModal(CheckOutStatusEnum.PAID)"
                    >
                        {{ $t('buttons.paid') }}
                    </CRButton>
                    <CRButton
                        v-if="isPaidAndNotPaidBtnVisible"
                        pattern="thin"
                        color="danger"
                        @click="openConfirmationModal(CheckOutStatusEnum.NOT_PAID)"
                    >
                        {{ $t('buttons.notPaid') }}
                    </CRButton>
                    <CRButton
                        v-if="isCompletedBtnVisible"
                        pattern="thin"
                        color="secondary"
                        @click="openConfirmationModal(CheckOutStatusEnum.COMPLETED)"
                    >
                        {{ $t('buttons.completed') }}
                    </CRButton>
                </div>

                <CRButton @click="openCheckOutPage" v-if="isViewBtnVisible" pattern="thin">
                    {{ $t('buttons.view') }}
                </CRButton>
            </div>
        </div>
    </div>
</template>

<script>
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import CheckOutCard from '@/components/OrderManagement/CheckOutCard.vue';
    import OMTitleSection from '@/components/OrderManagement/OMTitleSection.vue';
    import OMStatus from '@/components/OrderManagement/OMStatus.vue';
    import CRTag from '@/components/Common/Tags/CRTag.vue';
    import { getPaymentTypeName } from '@/helpers/PaymentHelper';
    import { CheckOutStatusEnum, CheckOutTypeEnum, IndividualOrderStatusEnum, OrderStatusEnum } from '@/utils/enums';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CheckOutItem',
        components: { CRButton, CheckOutCard, OMTitleSection, OMStatus, CRTag },
        props: {
            checkOut: {
                type: Object,
                default: () => {},
            },
        },
        emits: ['openConfirmationModal'],
        data() {
            return {
                CheckOutStatusEnum,
                getPaymentTypeName,
            };
        },
        computed: {
            ...mapGetters('auth', ['isAdmin']),

            isPaidAndNotPaidBtnVisible() {
                return this.checkOut.status === CheckOutStatusEnum.WAITING_PAYMENT;
            },

            isCompletedBtnVisible() {
                return this.checkOut.status === CheckOutStatusEnum.PAID;
            },

            isViewBtnVisible() {
                return (
                    this.checkOut.status === CheckOutStatusEnum.NEW ||
                    this.checkOut.status === CheckOutStatusEnum.ORDER_APPROVED ||
                    (this.checkOut.status === CheckOutStatusEnum.WAITING_PAYMENT && !this.isAdmin) ||
                    this.checkOut.status === CheckOutStatusEnum.DECLINED ||
                    this.checkOut.status === CheckOutStatusEnum.COMPLETED ||
                    this.checkOut.status === CheckOutStatusEnum.PAID ||
                    this.checkOut.status === CheckOutStatusEnum.NOT_PAID ||
                    this.checkOut.status === CheckOutStatusEnum.ORDER_REQUEST
                );
            },
        },
        methods: {
            openConfirmationModal(status) {
                this.$emit('openConfirmationModal', { id: this.checkOut.id, status });
            },

            renderKitEvents(checkOut) {
                if (checkOut.type === CheckOutTypeEnum.INDIVIDUAL_ORDER) {
                    const array = [];

                    if (checkOut.order.status === IndividualOrderStatusEnum.CANCELLATION_REQUEST) {
                        const title = `1 ${this.$t('orderManagement.kitCancellationRequest')}`;
                        const tagType = 'danger-fill';

                        array.push({ title, tagType });
                    }

                    if (checkOut.order.status === IndividualOrderStatusEnum.DECLINED) {
                        const title = `1 ${this.$t('orderManagement.kitCanceled')}`;
                        const tagType = 'danger-fill';

                        array.push({ title, tagType });
                    }

                    return array;
                }

                if (checkOut.type === CheckOutTypeEnum.REGULAR_ORDER) {
                    const array = [];

                    const ordersWithUserDeclinedStatus = checkOut.orders.filter(
                        (order) => order.status === OrderStatusEnum.CANCELED_BY_USER
                    );

                    const ordersWithAdminDeclinedStatus = checkOut.orders.filter(
                        (order) => order.status === OrderStatusEnum.CANCELED_BY_ADMIN
                    );

                    if (ordersWithUserDeclinedStatus.length) {
                        const title = `${ordersWithUserDeclinedStatus.length} ${this.$t(
                            'orderManagement.kitsCanceled'
                        )}`;
                        const subtitle = this.$t('orderManagement.byBuyer');
                        const tagType = 'danger-fill';

                        array.push({ title, subtitle, tagType });
                    }

                    if (ordersWithAdminDeclinedStatus.length) {
                        const title = `${ordersWithAdminDeclinedStatus.length} ${this.$t(
                            'orderManagement.kitsCanceled'
                        )}`;
                        const subtitle = this.$t('orderManagement.byProvider');
                        const tagType = 'danger-fill';

                        array.push({ title, subtitle, tagType });
                    }

                    return array;
                }
            },

            openCheckOutPage() {
                this.$router.push({
                    name: this.isAdmin ? 'adminCheckOut' : 'userCheckOut',
                    params: { check_out_id: this.checkOut.id },
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .check-out-item {

        &__deleted {
            .mobile-card {
                background: rgba($color: red, $alpha: 0.12) !important;
            }

            .desktop-row {
                background: rgba($color: red, $alpha: 0.12) !important;
            }
        }

        .desktop-row {
            display: none;
        }

        .kit-events {
            @include row-align-start;

            margin-top: 10px;

            .kit-events__tag-wrapper {
                @include column-align-start;

                .tag {
                    padding-bottom: 5px;
                    padding-top: 5px;
                    margin-bottom: 12px;

                    text-align: end;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    :deep(.tag-container__text) {
                        @include column-align-end;
                    }

                    p,
                    span {
                        font-weight: 400;
                        font-style: italic;
                        font-size: $font-14;
                        line-height: 110%;
                    }
                }
            }
        }

        // mobile-card
        .mobile-card {
            &:hover {
                border: 3px solid $primary;
                background-color: $primary-500;
            }

            .status {
                max-width: 60%;
            }

            .footer {
                @include row-align-center-justify-between;
                flex-wrap: wrap;

                .btn {
                    width: 100%;

                    margin-top: 10px;
                }
            }
        }
    }

    @include media($xxl) {
        .check-out-item {
            .kit-events {
                @include row-align-center-justify-center;

                margin-top: 0;
            }

            .desktop-row {
                @include row-align-center-justify-between;

                background: $white;
                box-shadow: $box-shadow-dark;

                border-radius: 10px;

                padding: 10px;

                transition: all 0.2s linear;

                border: 3px solid transparent;

                &:hover {
                    border: 3px solid $primary;
                    background-color: $primary-500;
                }

                .row-item {
                    text-align: center;
                    width: 14%;
                    position: relative;

                    p {
                        font-size: $font-14;
                    }
                }

                .order-status {
                    @include row-align-center-justify-center;

                    width: 16%;
                }

                .kit-amount,
                .order-price,
                .actions {
                    width: 8%;
                }

                .user-info {
                    width: 14%;
                }

                .order-date {
                    width: 10%;
                }

                .actions {
                    @include column-align-end;
                }

                .order-number {
                    width: 12%;
                }
            }

            // mobile-card
            .mobile-card {
                display: none;
            }
        }
    }
</style>
