<template>
    <div class="om-title-section" :class="{ reverse }">
        <p v-if="title">{{ title }}</p>
        <h6 v-if="text">{{ text }}</h6>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'OMTitleSection',
        props: {
            title: {
                type: String,
                default: '',
            },
            text: {
                type: [String, Number],
                default: '',
            },
            reverse: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .reverse {
        display: flex;
        flex-direction: column-reverse;
    }
</style>
