<template>
    <div class="table-header">
        <h6 class="order-number">
            {{ $t('orderManagement.orderNumber') }}
        </h6>
        <div class="vertical-separator" />

        <h6 class="order-date">
            {{ $t('orderManagement.orderDate') }}
        </h6>
        <div class="vertical-separator" />

        <h6 class="order-status">
            {{ $t('orderManagement.orderStatus') }}
        </h6>
        <div class="vertical-separator" />

        <h6>
            {{ $t('orderManagement.paymentMethod') }}
        </h6>
        <div class="vertical-separator" />

        <h6>{{ $t('orderManagement.kitEvents') }}</h6>
        <div v-if="isAdmin" class="vertical-separator" />
        <h6 class="user-info" v-if="isAdmin">
            Klientas
        </h6>
        <div class="vertical-separator" />

        <h6 class="discount-code">
            {{ $t('orderManagement.discountCode') }}
        </h6>
        <div class="vertical-separator" />

        <h6 class="order-price">
            {{ $t('orderManagement.orderPrice') }}
        </h6>
        <div class="vertical-separator" />

        <h6 class="action">
            {{ $t('orderManagement.actions') }}
        </h6>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'TableHeader',
        computed: {
            ...mapGetters('auth', ['isAdmin']),
        }
    };
</script>

<style lang="scss" scoped>
    .table-header {
        display: none;
    }

    @include media($xxl) {
        .table-header {
            padding: 10px;
            @include row-align-center-justify-between;
            margin-bottom: 10px;

            border: 3px solid transparent;

            h6 {
                width: 14%;
                text-align: center;
                position: relative;
            }

            .kit-amount,
            .action,
            .order-price {
                width: 8%;
            }

            .user-info {
                width: 14%;
            }

            .order-date {
                width: 10%;
            }

            .order-number {
                width: 12%;
            }

            .order-status {
                width: 16%;
            }

            .discount-code {
                width: 8%;
            }
        }
    }
</style>
